import React from 'react';
import useData from './hooks';
import { FilteredListResolverProps } from './types';

/**
 * Ce composant permet d'avoir un useEffect différent pour chaque Champ avec une SL (shared list ou liste partagée)
 * @param param0
 * @returns
 */
const FilteredListResolver = ({
  fieldListWithFilter,
  updateDeclaration,
  forceFieldUpdate,
  otherFields,
}: FilteredListResolverProps) => {
  useData(fieldListWithFilter, updateDeclaration, forceFieldUpdate, otherFields);
  return <></>;
};

export default FilteredListResolver;

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import fetchDataAndUpdateState from './dataFetcher';
import {
  AllFieldValueTypes,
  CtrlRefOperator,
  CtrlRefUserParemeterOperator,
  FieldDesc,
  HierarchicalChoice,
} from '90.quickConnect.Models/models';
import { ListDesc } from '90.quickConnect.Models/models/fields/fieldDesc/otherFIelds/listDesc';
import { FilterDef } from '90.quickConnect.Models/models/declarations/filterDef';
import { useQCUpdateFunctionsContext } from '20.formLib/DeclarationContainer/contexts';
import { OperatorResult, QcOperator } from '90.quickConnect.Models/enums';
import { evalComparisonWithItems } from '20.formLib/helpers/evals/evalComparisonLogic';
import { useStore } from '30.quickConnect.Stores';

const useData = (
  fieldListWithFilter: ListDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  updateFieldErrors: (mandatoryFieldFullPathId: string, newErrors: string[]) => void,
  otherFields: FieldDesc[],
) => {
  const {
    SharedListStore: { getSharedList },
    LoginStore: {
      signInInfos: { userParameterValue },
    },
  } = useStore();

  const previousControlValue = useRef<AllFieldValueTypes>();
  const previousUserParam = useRef<string>();

  const { updateProperty } = useQCUpdateFunctionsContext();

  const { filterDef, listId, fullPathId, fieldType } = fieldListWithFilter;

  const [getCriteriaValueOperator, setGetCriteriaValueOperator] = useState<
    CtrlRefOperator | CtrlRefUserParemeterOperator | undefined
  >();
  const [comparaisonOperatorType, setComparaisonOperatorType] = useState<QcOperator | undefined>();
  const [getItemProperty, setGetItemProperty] = useState<string | undefined>();
  const [showAllIfNoValidCriteria, setShowAllIfNoValidCriteria] = useState<boolean | undefined>();
  const [includeIfNoValidItemProperty, setIncludeIfNoValidItemProperty] = useState<boolean | undefined>();

  useEffect(() => {
    const parsedFilter: FilterDef = typeof filterDef === 'string' ? JSON.parse(filterDef) : filterDef;
    const {
      getCriteriaValueOperator: parsedGetCriteriaValueOperator,
      comparaisonOperatorType: parsedComparaisonOperatorType,
      getItemProperty: parsedGetItemProperty,
      showAllIfNoValidCriteria: parsedShowAllIfNoValidCriteria,
      includeIfNoValidItemProperty: parsedIncludeIfNoValidItemProperty,
    } = parsedFilter;
    setGetCriteriaValueOperator(parsedGetCriteriaValueOperator);
    setComparaisonOperatorType(parsedComparaisonOperatorType);
    setGetItemProperty(parsedGetItemProperty);
    setShowAllIfNoValidCriteria(parsedShowAllIfNoValidCriteria);
    setIncludeIfNoValidItemProperty(parsedIncludeIfNoValidItemProperty);
  }, [filterDef]);

  /**
   * Récupèrer le fullPathId si dans un RG
   */
  const getScopedControlId = useCallback(
    (rawControlId: string, targetFullPathId: string): string => {
      const targetParts = targetFullPathId.split('.');
      const rawParts = rawControlId.split('.');

      const rawFieldId = rawParts[rawParts.length - 1];

      // On construit un scope local basé sur targetFullPathId (on enlève juste la dernière partie)
      const baseScope = targetParts.slice(0, targetParts.length - 1).join('.');

      const scopedId = `${baseScope}.${rawFieldId}`;

      const scopedFieldExists = otherFields.some((field) => field.fullPathId === scopedId);

      return scopedFieldExists ? scopedId : rawControlId;
    },
    [otherFields],
  );

  /**
   * Récupère dynamiquement la valeur du champ contrôleur.
   */
  const controlFieldValue = useMemo(() => {
    if (getCriteriaValueOperator?.type === 5) {
      const baseControlId = (getCriteriaValueOperator as CtrlRefOperator).controlId;
      const scopedControlId = getScopedControlId(baseControlId, fullPathId);
      const controlField = otherFields.find((field) => field.fullPathId === scopedControlId);
      return controlField?.value;
    }
    return undefined;
  }, [getCriteriaValueOperator, getScopedControlId, fullPathId, otherFields]);

  /**
   * Filtre récursivement une liste hiérarchique selon un opérateur et une valeur.
   */
  const filterHierarchicalChoices = useCallback(
    (choices: HierarchicalChoice[], userParamValue: string | undefined, comparator: QcOperator) => {
      const filterRecursive = (hchoices: any[]) =>
        hchoices.reduce((acc, choice) => {
          const matched = evalComparisonWithItems(
            comparator,
            choice.value,
            OperatorResult.STRING,
            userParamValue,
            OperatorResult.STRING,
          );
          if (matched || (choice.children && filterRecursive(choice.children).length > 0)) {
            acc.push({
              ...choice,
              children: choice.children ? filterRecursive(choice.children) : [],
            });
          }
          return acc;
        }, []);
      return filterRecursive(choices);
    },
    [],
  );

  useEffect(() => {
    async function fetchData() {
      await fetchDataAndUpdateState({
        listId,
        comparaisonOperatorType,
        getSharedList,
        getCriteriaValueOperator,
        userParameterValue,
        previousUserParam,
        fieldType,
        filterHierarchicalChoices,
        showAllIfNoValidCriteria,
        includeIfNoValidItemProperty,
        updateProperty,
        fullPathId,
        getItemProperty,
        controlFieldValue,
        previousControlValue,
      });
    }

    fetchData();
  }, [
    listId,
    fullPathId,
    controlFieldValue,
    userParameterValue,
    updateProperty,
    getCriteriaValueOperator,
    comparaisonOperatorType,
    getItemProperty,
    showAllIfNoValidCriteria,
    getSharedList,
    fieldType,
    filterHierarchicalChoices,
    filterDef,
    includeIfNoValidItemProperty,
  ]);
};

export default useData;

import { evalComparisonWithItems } from '../../../../helpers/evals/evalComparisonLogic';
import { FieldType, OperatorResult, QcOperator } from '90.quickConnect.Models/enums';
import {
  AllFieldValueTypes,
  Choice,
  CtrlRefOperator,
  CtrlRefUserParemeterOperator,
  HierarchicalChoice,
  NamedUserParameterValue,
  SharedList,
} from '90.quickConnect.Models/models';
import { getValueFromChoiceProperties } from '80.quickConnect.Core/helpers/common';

function filterHierarchicalTree(
  choices: HierarchicalChoice[],
  currentValues: string[],
  comparaisonOperatorType: QcOperator,
  getItemProperty: string,
  includeIfNoValidItemProperty: boolean | undefined,
): HierarchicalChoice[] {
  return choices
    .map((choice) => {
      // eslint-disable-next-line prefer-destructuring
      const itemValue = getValueFromChoiceProperties([choice], [getItemProperty], false)[0];

      const fallbackItemValue = itemValue === '' && includeIfNoValidItemProperty ? currentValues[0] : itemValue;

      const isMatch = currentValues.some((val) =>
        evalComparisonWithItems(
          comparaisonOperatorType,
          fallbackItemValue,
          OperatorResult.STRING,
          val,
          OperatorResult.STRING,
        ),
      );

      const filteredChildren = choice.children
        ? filterHierarchicalTree(
            choice.children,
            currentValues,
            comparaisonOperatorType,
            getItemProperty,
            includeIfNoValidItemProperty,
          )
        : [];

      if (isMatch || filteredChildren.length > 0) {
        return {
          ...choice,
          children: filteredChildren,
        };
      }

      return null;
    })
    .filter((choice): choice is HierarchicalChoice => choice !== null);
}

const fetchDataAndUpdateState = async (options: {
  listId: string | undefined;
  comparaisonOperatorType: QcOperator | undefined;
  getSharedList: (sharedListId: string) => Promise<SharedList | undefined>;
  getCriteriaValueOperator: CtrlRefUserParemeterOperator | CtrlRefOperator | undefined;
  userParameterValue: NamedUserParameterValue[];
  previousUserParam: React.MutableRefObject<string | undefined>;
  fieldType: FieldType;
  filterHierarchicalChoices: (
    choices: HierarchicalChoice[],
    userParamValue: string | undefined,
    comparator: QcOperator,
  ) => any;
  showAllIfNoValidCriteria: boolean | undefined;
  includeIfNoValidItemProperty: boolean | undefined;
  updateProperty: (fullPathIdPropertyField: string, property: string, propertyValue: unknown) => void;
  fullPathId: string;
  getItemProperty: string | undefined;
  controlFieldValue: AllFieldValueTypes;
  previousControlValue: React.MutableRefObject<AllFieldValueTypes | undefined>;
}) => {
  const {
    listId,
    comparaisonOperatorType,
    getSharedList,
    getCriteriaValueOperator,
    userParameterValue,
    previousUserParam,
    fieldType,
    filterHierarchicalChoices,
    showAllIfNoValidCriteria,
    includeIfNoValidItemProperty,
    updateProperty,
    fullPathId,
    getItemProperty,
    controlFieldValue,
    previousControlValue,
  } = options;
  let filteredChoices: Choice[] | HierarchicalChoice[] = [];

  if (listId && comparaisonOperatorType) {
    const sharedList = await getSharedList(listId);
    if (!sharedList || !sharedList.data) return;

    // Cas : Critère basé sur un paramètre utilisateur
    if (getCriteriaValueOperator?.type === 43) {
      const userParamValue = userParameterValue.find(
        (p) => p.userParameterId === (getCriteriaValueOperator as CtrlRefUserParemeterOperator).parameterId,
      )?.value;
      if (userParamValue && previousUserParam.current !== userParamValue) {
        filteredChoices =
          fieldType === FieldType.HierarchicalList
            ? filterHierarchicalChoices(sharedList.hierarchicalChoices, userParamValue, comparaisonOperatorType)
            : sharedList.data.filter((choice) =>
                evalComparisonWithItems(
                  comparaisonOperatorType,
                  choice.value,
                  OperatorResult.STRING,
                  userParamValue,
                  OperatorResult.STRING,
                ),
              );
        previousUserParam.current = userParamValue;
      } else if (!userParamValue) {
        filteredChoices = showAllIfNoValidCriteria
          ? fieldType === FieldType.HierarchicalList
            ? sharedList.hierarchicalChoices
            : sharedList.data
          : [];
        updateProperty(fullPathId, 'listChoice', filteredChoices);
        return;
      }
    }

    // Cas : Critère basé sur un champ contrôleur
    else if (getCriteriaValueOperator?.type === 5 && getItemProperty) {
      if (controlFieldValue) {
        let currentValues: string[] = [];

        if (Array.isArray(controlFieldValue)) {
          if (controlFieldValue.length > 1) {
            currentValues = controlFieldValue.map((item) => (item as Choice | HierarchicalChoice)?.value);
          } else {
            currentValues = controlFieldValue[0] ? [(controlFieldValue[0] as Choice | HierarchicalChoice)?.value] : [];
          }
        } else {
          currentValues = [(controlFieldValue as Choice | HierarchicalChoice)?.value];
        }
        if (
          currentValues.length > 0 &&
          previousControlValue.current !== currentValues &&
          currentValues[0] !== undefined
        ) {
          if (fieldType === FieldType.HierarchicalList) {
            filteredChoices = filterHierarchicalTree(
              sharedList.hierarchicalChoices,
              currentValues,
              comparaisonOperatorType,
              getItemProperty,
              includeIfNoValidItemProperty,
            );
          } else {
            filteredChoices = sharedList.data.filter((choice) => {
              // eslint-disable-next-line prefer-destructuring
              let itemValue = getValueFromChoiceProperties([choice], [getItemProperty], false)[0];
              if (itemValue === '' && includeIfNoValidItemProperty)
                // eslint-disable-next-line prefer-destructuring
                itemValue = currentValues[0];

              return currentValues.some((val) =>
                evalComparisonWithItems(
                  comparaisonOperatorType,
                  itemValue,
                  OperatorResult.STRING,
                  val,
                  OperatorResult.STRING,
                ),
              );
            });
          }

          previousControlValue.current = currentValues;
        } else {
          updateProperty(fullPathId, 'value', undefined);
          updateProperty(fullPathId, 'listChoice', []);
          return;
        }
      } else {
        filteredChoices =
          showAllIfNoValidCriteria && controlFieldValue
            ? fieldType === FieldType.HierarchicalList
              ? sharedList.hierarchicalChoices
              : sharedList.data
            : [];
        updateProperty(fullPathId, 'listChoice', filteredChoices);
        return;
      }
    }
  }
  if (filteredChoices.length > 0) updateProperty(fullPathId, 'listChoice', filteredChoices);
};

export default fetchDataAndUpdateState;

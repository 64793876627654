import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  date1IsBeforeDate2,
  date1IsAfterDate2,
} from '20.formLib/DeclarationContainer/AllResolvers/ErrorsResolver/ErrorResolver/helpers/dateValidator';
import { useStore } from '30.quickConnect.Stores';
import { ErrorsReference } from '20.formLib/DeclarationContainer/AllResolvers/ErrorsResolver/ErrorResolver/types';
import { isDate } from '80.quickConnect.Core/helpers/common';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import {
  FieldDesc,
  AddressData,
  NumericDesc,
  ComboDesc,
  DateTimeDesc,
  QCNotification,
  EditDesc,
} from '90.quickConnect.Models/models';
import { DateTimeFieldType, FieldType, Mandatory } from '90.quickConnect.Models/enums';
import { isMandatoryItems } from '90.quickConnect.Models/mappings/fields/isMandatoryItems';
import { isAFieldContainer } from '90.quickConnect.Models/guards';
import { compareDeepValues } from '80.quickConnect.Core/helpers';

const dateErrorFormat = (date: string | Date, dateTimeDesc: DateTimeDesc) => {
  return format(new Date(date), dateTimeDesc.type === DateTimeFieldType.DateTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy');
};
const useData = (
  fieldToCheck: FieldDesc,
  updateFieldErrors: (mandatoryFieldFullPathId: string, errors: string[]) => void,
  allFieldsToCkeck: FieldDesc[],
  flattenFields: FieldDesc[],
): void => {
  const { t } = useTranslation('declaration');

  // UseRef
  const errorsReference = useRef<ErrorsReference>(new Map());
  const errorsMemoRef = useRef<string[]>([]);

  // Custom hooks
  const {
    EquipmentsStore: { findIsInDataSource },
  } = useStore();

  const getError = useCallback((fullPathId: string): string[] => {
    if (!errorsReference.current.has(fullPathId)) return [];

    return Array.from(errorsReference.current.get(fullPathId)!.values());
  }, []);

  const hasErrorForThisFieldAndProperty = useCallback((fullPathId: string, property: string): boolean => {
    if (!errorsReference.current.has(fullPathId)) return false;

    const err = errorsReference.current.get(fullPathId)!;

    return err.has(property) && !!err.get(property);
  }, []);

  /**
   * Met a jour la référence errorsReference, soit en rajoutant l'erreur, soit en la mettant a jour
   */
  const putError = useCallback((fullPathId: string, attributeInError: string, errorMsg: string) => {
    if (errorsReference.current.has(fullPathId)) {
      // Mise a jour
      const errorToUpdate = errorsReference.current.get(fullPathId)!;

      // Création ou Mise a jour de l'attribut en erreur
      errorToUpdate.set(attributeInError, errorMsg);
      errorsReference.current.set(fullPathId, errorToUpdate);
    } else {
      // Ajout
      const errorToInsert = new Map<string, string>();
      errorToInsert.set(attributeInError, errorMsg);
      errorsReference.current.set(fullPathId, errorToInsert);
    }
  }, []);

  /**
   * Supprime l'attribut dans la référence errorsReference
   */
  const deleteError = useCallback((fullPathId: string, attributeInError: string) => {
    if (!errorsReference.current.has(fullPathId)) return;

    if (!errorsReference.current.get(fullPathId)!.has(attributeInError)) return;

    if (!errorsReference.current.get(fullPathId)!.delete(attributeInError)) return;

    if (errorsReference.current.get(fullPathId)!.size === 0) errorsReference.current.delete(fullPathId);
  }, []);

  /**
   * Permet de vérifier que le champ est renseigné
   */
  const checkMandatoryField = useCallback(
    (field: FieldDesc): string | undefined => {
      const { fieldType, value, mandatory, items, visible } = field;
      if (visible === false) return undefined;
      if ((!mandatory || mandatory === Mandatory.Optional) && !isAFieldContainer(field)) return undefined;

      switch (fieldType) {
        case FieldType.Address:
          if (value !== undefined) {
            const addressData = value as AddressData;
            if (!addressData.zipCode || addressData.zipCode === '' || !addressData.city || addressData.city === '')
              return t('errors_mandatory_field').toString();
          } else if (value === undefined || value === '' || value === ([] as any))
            return t('errors_mandatory_field').toString();
          break;

        case FieldType.DateTime:
          if (!value) return t('errors_mandatory_field').toString();
          break;

        case FieldType.RadioList:
        case FieldType.Combo:
          if (!value) return t('errors_mandatory_field').toString();
          break;

        case FieldType.CheckBox:
          // Une checkbox n'a pas de caractère obligatoire par défaut (seulement true ou false)
          break;

        case FieldType.Include: {
          if (!isMandatoryItems(field)) return undefined;

          const itemsMandatory = items
            .map(checkMandatoryField)
            .filter((i: string | undefined) => typeof i === 'string');

          return itemsMandatory.length > 0 ? t('errors_mandatory_field').toString() : undefined;
        }

        case FieldType.Group:
        case FieldType.RepeatableGroup:
        case FieldType.ImagesGroup:
        case FieldType.Dialog: {
          // case FieldType.Include:
          if (isMandatoryItems(field)) {
            const itemsMandatory = items.some((i) => typeof checkMandatoryField(i) === 'string');

            // const results = itemsMandatory.filter((i: string | undefined) => typeof i === 'string');

            return itemsMandatory ? t('errors_mandatory_field').toString() : undefined;
          }
          break;
        }
        case FieldType.Slider:
          if (value === null || value === undefined) {
            return t('errors_mandatory_field').toString();
          }

          return undefined;

        case FieldType.Alert:
          const { defaultValue } = field as ComboDesc;
          if (
            value === false ||
            (value === undefined && defaultValue === undefined) ||
            value === '' ||
            (Array.isArray(value) && !value.length) ||
            value === null
          )
            return t('errors_mandatory_field').toString();
          break;

        case FieldType.Notification: {
          if (!value) return t('errors_mandatory_field').toString();
          const { selectedTargets } = field.value as QCNotification;

          if (selectedTargets.length === 0) return t('errors_mandatory_field').toString();
          break;
        }

        case FieldType.Text: {
          // Premier cas de figure. Champ Simple sans source de données
          if (!value || value === '') return t('errors_mandatory_field').toString();

          // Second cas de figure: Le champ est autoComplete avec une source de données.
          const { dataSource, fullPathId } = field as EditDesc;

          if (dataSource?.forcedSelection && !findIsInDataSource(fullPathId)) {
            return t('errors_mandatory_field').toString();
          }
          break;
        }

        default:
          if (value === false || value === undefined || value === '' || (Array.isArray(value) && !value.length))
            return t('errors_mandatory_field').toString();
          break;
      }
      return undefined;
    },
    [t, findIsInDataSource],
  );

  /**
   * Permet de gérer les autres erreurs
   */
  const checkOtherErrors = useCallback(
    (field: FieldDesc): void => {
      const { fieldType, value, fullPathId } = field;
      switch (fieldType) {
        case FieldType.Digits:
        case FieldType.Numeric:
          const numDesc = field as NumericDesc;
          const valueNum = value as number;
          if (valueNum !== undefined && value !== '') {
            if (value === '-') {
              putError(fullPathId, 'value', t('errors_edited_value'));
            } else {
              deleteError(fullPathId, 'value');
            }

            if (numDesc.min !== undefined && numDesc.min > valueNum) {
              putError(fullPathId, 'min', t('errors_min_field', { min: numDesc.min }));
            } else {
              deleteError(fullPathId, 'min');
            }

            if (numDesc.max !== undefined && numDesc.max < valueNum) {
              putError(fullPathId, 'max', t('errors_max_field', { max: numDesc.max }));
            } else {
              deleteError(fullPathId, 'max');
            }
          }
          break;

        case FieldType.DateTime:
          const dateTimeDesc = field as DateTimeDesc;
          const isDateTimeComparaison = dateTimeDesc.type === DateTimeFieldType.DateTime;
          if (isDate(value) && dateTimeDesc) {
            const dateVal = value as Date;
            const { min, max } = dateTimeDesc;
            // Cas des minimum...
            if (min) {
              const minValue = min.includes('controlId')
                ? DateTimeExtension.getDateFromControlRef(flattenFields, min)
                : min.includes('.')
                ? DateTimeExtension.getDateFromSplitString(flattenFields, min)
                : typeof min === 'string' && isDate(min)
                ? new Date(min)
                : undefined;

              if (minValue instanceof Date && date1IsBeforeDate2(dateVal, minValue, isDateTimeComparaison)) {
                putError(fullPathId, 'min', t('errors_min_field', { min: dateErrorFormat(minValue, dateTimeDesc) }));

                if (hasErrorForThisFieldAndProperty(fullPathId, 'value')) deleteError(fullPathId, 'value');
              } else {
                deleteError(fullPathId, 'min');

                if (hasErrorForThisFieldAndProperty(fullPathId, 'value')) deleteError(fullPathId, 'value');
              }
            }

            // Cas des maximum...
            if (max) {
              const maxValue = max.includes('controlId')
                ? DateTimeExtension.getDateFromControlRef(flattenFields, max)
                : max.includes('.')
                ? DateTimeExtension.getDateFromSplitString(flattenFields, max)
                : typeof max === 'string' && isDate(max)
                ? new Date(max)
                : undefined;

              if (maxValue instanceof Date && date1IsAfterDate2(dateVal, maxValue, isDateTimeComparaison)) {
                const errorMsg = t('errors_max_field', {
                  max: dateErrorFormat(maxValue, dateTimeDesc),
                });
                // Mettre a jour les réfs...
                putError(fullPathId, 'max', errorMsg);

                if (hasErrorForThisFieldAndProperty(fullPathId, 'value')) deleteError(fullPathId, 'value');
              } else {
                // Retrait de l'erreur
                deleteError(fullPathId, 'max');

                if (hasErrorForThisFieldAndProperty(fullPathId, 'value')) deleteError(fullPathId, 'value');
              }
            }

            if (!min && !max) {
              if (value instanceof Date && !Number.isNaN(value)) {
                deleteError(fullPathId, 'value');
              } else {
                putError(fullPathId, 'value', t('textFields_errors_forcedSelection'));
              }
            }
          } else {
            if (value) {
              putError(fullPathId, 'value', t('textFields_errors_forcedSelection'));
            } else {
              deleteError(fullPathId, 'value');
            }
          }

          break;
        default:
          break;
      }
    },
    [t, flattenFields, putError, deleteError, hasErrorForThisFieldAndProperty],
  );

  useEffect(() => {
    const { fullPathId, mandatory } = fieldToCheck;

    checkOtherErrors(fieldToCheck);

    const errorsToCheck = getError(fullPathId);

    if (
      (mandatory && (mandatory === Mandatory.Required || mandatory === Mandatory.ChildsValue)) ||
      isMandatoryItems(fieldToCheck)
    ) {
      const mandatoryCheck = checkMandatoryField(fieldToCheck);
      if (mandatoryCheck) errorsToCheck.push(mandatoryCheck);
    }

    if (!compareDeepValues(errorsToCheck, errorsMemoRef.current)) {
      updateFieldErrors(fullPathId, errorsToCheck);
      errorsMemoRef.current = errorsToCheck;
    }
  }, [checkMandatoryField, checkOtherErrors, getError, fieldToCheck, t, updateFieldErrors]);
};

export default useData;

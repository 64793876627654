import { useMemo } from 'react';
import { UseDataProps } from './types';
import { FieldDesc } from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';
import { ListDesc } from '90.quickConnect.Models/models/fields/fieldDesc/otherFIelds/listDesc';

/**
 * Hook qui détermine les dépendances d'un filtre sur une SL
 * @param flattenFields
 * @returns
 */
const useData = (flattenFields: FieldDesc[]): UseDataProps => {
  const allListFieldsWithFilter = useMemo(() => {
    const listFieldsTypes = new Set([
      FieldType.Combo,
      FieldType.RadioList,
      FieldType.HierarchicalList,
      FieldType.CheckBoxList,
    ]);
    return flattenFields
      .filter(({ fieldType }) => listFieldsTypes.has(fieldType))
      .map((f) => f as ListDesc)
      .filter(({ filterDef }) => filterDef !== undefined);
  }, [flattenFields]);

  return { allListFieldsWithFilter };
};

export default useData;

import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTheme, Box, CircularProgress, Chip, IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useData from './hooks';
import CheckBoxListRightContent from './CheckBoxListRightContent';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { FlexRow, FlexColumn, BoxForLabel } from '50.quickConnect.Fields/styles';
import { FieldMainBox } from '50.quickConnect.Fields/styles';
import TwoSidesRenderer from '50.quickConnect.Fields/TwoSidesRenderer';
import { Choice } from '90.quickConnect.Models/models';
import ChevronRight from '10.quickConnect.app/components/shared/ChevronRight';
import { dippedGreen500 } from '00.app/theme';
const CheckBoxListQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  selectedIndex,
  setSelectedIndex,
  isReadOnly,
  labelClass,
  openByImagesGroupField,
}: ValueFieldsTypesProps) => {
  const { value, fullPathId } = field;
  const {
    t,
    choices,
    id,
    nameWithId,
    toolTip,
    deleteItem,
    isItemDeleted,
    setIsItemDeleted,
    handleDeleteAll,
    isDeleteAll,
    setIsDeleteAll,
  } = useData(field, updateDeclaration);

  useEffect(() => {
    if (Array.isArray(value) && value.length > 0 && Array.isArray(choices) && choices.length > 0) {
      const filteredValue = (value as Choice[]).filter((v) => choices.some((choice) => choice.value === v.value));

      // Si des éléments ont été supprimés, on met à jour la déclaration
      if (filteredValue.length !== value.length) {
        updateDeclaration(fullPathId, filteredValue);
        setSelectedIndex(undefined);
      }
    }
  }, [choices, value, fullPathId, updateDeclaration, setSelectedIndex]);

  const { breakpoints, palette } = useTheme();

  const labelBoxWithoutChevron = useMemo(
    () => (
      <Box sx={FlexRow}>
        <Box sx={{ flexGrow: 1 }} id={`${nameWithId}-label`}>
          {labelSpan}
        </Box>
        {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
      </Box>
    ),
    [id, labelSpan, nameWithId, toolTip],
  );
  const labelBox = useMemo(
    () => (
      <Box sx={FlexRow}>
        <Box sx={{ flexGrow: 1 }} id={`${nameWithId}-label`} component={'span'}>
          {labelSpan}
        </Box>
        {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
        {(value as Choice[])?.length > 0 && (
          <IconButton data-cy={`${nameWithId}-clearAll`} disabled={isReadOnly} onClick={() => handleDeleteAll()}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        )}
        <ChevronRight title={t('open_list').toString()} nameWithId color="secondary" />
      </Box>
    ),
    [handleDeleteAll, id, isReadOnly, labelSpan, nameWithId, t, toolTip, value],
  );

  if (openByImagesGroupField)
    return (
      <>
        {choices.length > 0 ? (
          <CheckBoxListRightContent
            isDeleteAll={isDeleteAll}
            setIsDeleteAll={setIsDeleteAll}
            setIsItemDeleted={setIsItemDeleted}
            isItemDeleted={isItemDeleted}
            choices={choices}
            updateDeclaration={updateDeclaration}
            labelBox={labelBoxWithoutChevron}
            field={field}
          />
        ) : (
          <CircularProgress />
        )}
      </>
    );

  return (
    <Box className={labelClass} sx={FieldMainBox(breakpoints)} data-cy={nameWithId} id={`scrollToAnchor-${fullPathId}`}>
      <Box
        data-cy={`${nameWithId}-OpenRightPanelBox`}
        sx={{ ...BoxForLabel(palette), borderColor: selectedIndex === id ? dippedGreen500 : '' }}
        onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
      >
        {labelBox}
        {Array.isArray(choices) && choices.length > 0 && (
          <Box
            data-cy={`${nameWithId}-resume`}
            sx={FlexColumn}
            onClick={() => !isReadOnly && setSelectedIndex(fullPathId)}
          >
            <Box>
              {((Array.isArray(value) ? value : []) as Choice[]).map((c) => {
                if (!c) return null;
                // Ne pas afficher si l'élément n'est pas dans la liste des choices
                const existsInChoices = choices.some((choice) => choice.value === c.value);
                if (!existsInChoices) return null;
                return (
                  <Chip
                    disabled={isReadOnly}
                    onDelete={() => deleteItem(c)}
                    sx={{ marginRight: '5px' }}
                    key={`${c.value}-${c.label}`}
                    label={c.label ?? choices.find((choice) => choice.value === c.value)?.label ?? c.value}
                  />
                );
              })}
            </Box>
          </Box>
        )}
      </Box>

      {selectedIndex && selectedIndex === fullPathId && (
        <TwoSidesRenderer
          parentNameAndId={nameWithId}
          onClose={() => setSelectedIndex(undefined)}
          open={selectedIndex === fullPathId}
        >
          {choices ? (
            <CheckBoxListRightContent
              isDeleteAll={isDeleteAll}
              setIsDeleteAll={setIsDeleteAll}
              setIsItemDeleted={setIsItemDeleted}
              isItemDeleted={isItemDeleted}
              choices={choices}
              updateDeclaration={updateDeclaration}
              labelBox={labelBoxWithoutChevron}
              field={field}
            />
          ) : (
            <CircularProgress />
          )}
        </TwoSidesRenderer>
      )}
    </Box>
  );
};
export default observer(CheckBoxListQcField);

import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTheme, Box, FormLabel, Chip, CircularProgress } from '@mui/material';
import useData from './hooks';
import RadioListRightContent from './RadioListRightContent';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { BoxForLabel, FlexRow } from '50.quickConnect.Fields/styles';
import { FieldMainBox } from '50.quickConnect.Fields/styles';
import TwoSidesRenderer from '50.quickConnect.Fields/TwoSidesRenderer';
import { Choice } from '90.quickConnect.Models/models';
import { dippedGreen500 } from '00.app/theme';
import ChevronRight from '10.quickConnect.app/components/shared/ChevronRight';

const RadioListQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  selectedIndex,
  setSelectedIndex,
  isReadOnly,
  labelClass,
  openByImagesGroupField,
}: ValueFieldsTypesProps) => {
  const { choices, nameWithId, toolTip, fullPathId, id, t } = useData(field, updateDeclaration);
  const { breakpoints, palette } = useTheme();
  const initialLabelBox = useMemo(
    () => (
      <Box sx={FlexRow}>
        <FormLabel id={`${nameWithId}-label`} htmlFor={`${nameWithId}-select`} sx={{ flexGrow: 1, cursor: 'pointer' }}>
          {labelSpan}
        </FormLabel>
        <ChevronRight title={t('open_list').toString()} nameWithId color="secondary" />

        {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
      </Box>
    ),
    [id, labelSpan, nameWithId, t, toolTip],
  );

  const dupliqueLabelBox = useMemo(
    () => (
      <Box sx={FlexRow}>
        <FormLabel id={`${nameWithId}-label-duliquer`} htmlFor={`${nameWithId}-select`}>
          {labelSpan}
        </FormLabel>
        {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
      </Box>
    ),
    [id, labelSpan, nameWithId, toolTip],
  );

  if (openByImagesGroupField)
    return (
      <>
        {choices ? (
          choices && (
            <RadioListRightContent
              choices={choices}
              updateDeclaration={updateDeclaration}
              labelBox={dupliqueLabelBox}
              field={field}
            />
          )
        ) : (
          <CircularProgress />
        )}
      </>
    );

  return (
    <Box
      className={labelClass}
      sx={FieldMainBox(breakpoints)}
      data-cy={`${nameWithId}`}
      id={`scrollToAnchor-${field.fullPathId}`}
    >
      <Box
        data-cy={`${nameWithId}-OpenRightPanelBox`}
        sx={{ ...BoxForLabel(palette), borderColor: selectedIndex === fullPathId ? dippedGreen500 : '' }}
        onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
      >
        {initialLabelBox}
        {field.value && choices?.some((choice) => choice.value === (field.value as Choice).value) ? (
          <Box>
            <Chip
              data-cy={`${nameWithId}-resume`}
              onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
              label={(field.value as Choice).label}
            />
          </Box>
        ) : null}
      </Box>

      {!isReadOnly && selectedIndex === fullPathId && (
        <TwoSidesRenderer
          parentNameAndId={nameWithId}
          onClose={() => setSelectedIndex(undefined)}
          open={selectedIndex === fullPathId}
        >
          {choices ? (
            choices && (
              <RadioListRightContent
                choices={choices}
                updateDeclaration={updateDeclaration}
                labelBox={dupliqueLabelBox}
                field={field}
              />
            )
          ) : (
            <CircularProgress />
          )}
        </TwoSidesRenderer>
      )}
    </Box>
  );
};
export default observer(RadioListQcField);

import React from 'react';
import { observer } from 'mobx-react';
import { FormControl, RadioGroup, FormControlLabel, Radio, Box } from '@mui/material';
import useData from './hooks';
import { RadioListRightContentName } from './const';
import { RadioListRightContentProps } from './types';
import { ComboDesc } from '90.quickConnect.Models/models';
import SearchBar from '10.quickConnect.app/components/shared/SearchBar';
import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';

const RadioListRightContent: React.VFC<RadioListRightContentProps> = ({
  field,
  updateDeclaration,
  labelBox,
  choices,
}: RadioListRightContentProps) => {
  const comboDesc = field as ComboDesc;
  const { id } = comboDesc;
  const nameWithId = `${RadioListRightContentName}-${id}`;
  const { t, localValue, updateLocalState, label, setLabel, defaultChoices, handleClear, onSearch } = useData(
    comboDesc,
    updateDeclaration,
    choices,
  );
  return (
    <>
      <FormControl data-cy={nameWithId} sx={{ width: '100%' }}>
        {defaultChoices && (defaultChoices.length >= 10 || onSearch) && (
          <SearchBar
            toolTip={t('search_bar').toString()}
            filterInputValue={label}
            setFilter={setLabel}
            filter={label}
            applyFilter={() => {}}
            clearFilter={handleClear}
            setSearchIconToRight={false}
          />
        )}
        {labelBox}
        <RadioGroup sx={{ marginTop: '10px' }} value={localValue || ''} onChange={updateLocalState}>
          <FormControlLabel
            checked={localValue === '' ? localValue !== undefined : false}
            data-cy={`${nameWithId}-<<clear>>`}
            key={`${nameWithId}-<<clear>>`}
            value={''}
            control={<Radio />}
            label={''}
          />
          {defaultChoices.map((option) => (
            <FormControlLabel
              checked={StringExtension.isTheSame(
                // label¤valeur pour traiter le cas de doublons dans les listes, mais seule la valeur est stockée
                `${option.label}¤${option.value}`,
                typeof localValue === 'string' ? localValue : localValue?.value ?? '',
              )}
              data-cy={`${nameWithId}-${option.value}`}
              key={`${nameWithId}-${option.label}-${option.value}`}
              value={`${option.label}¤${option.value}`}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};
export default observer(RadioListRightContent);

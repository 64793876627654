import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataProps } from './types';
import { Choice, ComboDesc, AllFieldValueTypes } from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import { useQCSFormEvent } from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript/context';
import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';

const useData = (
  comboDesc: ComboDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  choices: Choice[],
): UseDataProps => {
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const { defaultValue, fullPathId, value, reference } = comboDesc;
  // label¤valeur pour traiter le cas de doublons dans les listes, mais seule la valeur est stockée
  const [localValue, setLocalValue] = useState<string>(
    value ? `${(value as Choice).label}¤${(value as Choice).value}` : '',
  );
  const [label, setLabel] = useState('');
  const [defaultChoices, setDefaultChoices] = useState<Choice[]>(choices);
  const [onSearch, setOnsearch] = useState<boolean>(false);
  const { isQCScriptForm } = useQCSFormEvent();

  // Permet de récupérer les valeurs des déclarations déjà pré-remplies
  const lookedForValue = useRef(false);
  useEffect(() => {
    if (value && (value as Choice) && lookedForValue.current === false) {
      setLocalValue(`${(value as Choice).label}¤${(value as Choice).value}`);
      lookedForValue.current = true;
    }
  }, [value]);

  // On définit les callbacks
  // Update du formulaire au global
  const updateGlobalState = useCallback(() => {
    lookedForValue.current = true;
    const selectedChoice = localValue
      ? choices.find((c) => StringExtension.isTheSame(`${c.label}¤${c.value}`, localValue))
      : '';
    updateDeclaration(fullPathId, selectedChoice);
  }, [choices, updateDeclaration, fullPathId, localValue]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const updateLocalState = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
      setLocalValue(newValue);
      debouncedUpdateGlobalState();
    },
    [debouncedUpdateGlobalState],
  );

  // Dans le cas où un qcscript a mis à jour la valeur du champ
  useEffect(() => {
    if (isQCScriptForm && typeof value === 'string') {
      setLocalValue((prevState: string) => {
        return prevState !== value ? value : prevState;
      });
      debouncedUpdateGlobalState();
    }
  }, [value, isQCScriptForm, debouncedUpdateGlobalState]);

  // Gestion de la valeur par défaut, on est obligé de la faire ici car les sharedList peuvent ne pas être présentes lors du mapping
  useEffect(() => {
    if (defaultValue && value === undefined) {
      let newValue: string | undefined;
      try {
        const parsedDefaultValue = JSON.parse(defaultValue);
        const { label: defaultLabel, value: defaultVal } = parsedDefaultValue;
        if (defaultLabel) {
          const selected = choices.find((c) => c.label === defaultLabel);
          if (selected) newValue = `${selected.label}¤${selected.value}`;
        }
      } catch {
        if (typeof defaultValue === 'string') newValue = defaultValue;
      }
      if (newValue) {
        setLocalValue(newValue);
        debouncedUpdateGlobalState();
      }
    }
  }, [choices, debouncedUpdateGlobalState, defaultValue, value]);

  const searchLabel = useCallback(
    (valueToSearch: string): void => {
      if (valueToSearch) {
        setOnsearch(true);
        setDefaultChoices(
          choices.filter((l) =>
            l.label.toLowerCase().replace(/\s/g, '').includes(valueToSearch.toLowerCase().replace(/\s/g, '')),
          ),
        );
      }
    },
    [choices],
  );

  const handleClear = useCallback((): void => {
    setLabel('');
    setDefaultChoices(choices);
    setOnsearch(false);
  }, [choices]);

  const debouncedSearch = useDebouncedCallback((valueToSearch) => {
    searchLabel(valueToSearch);
  }, debounceTime);

  useEffect(() => {
    if (label) {
      debouncedSearch(label);
    }
  }, [debouncedSearch, label]);

  // useEffect pour les références...
  useEffect(() => {
    if (reference) {
      const newLocalValue = value as Choice;
      setLocalValue((previousLocalState: string) =>
        `${newLocalValue?.label}¤${newLocalValue?.value}` !== previousLocalState
          ? `${newLocalValue?.label}¤${newLocalValue?.value}`
          : previousLocalState,
      );
    }
  }, [reference, value]);

  // On retourne les valeurs à la vue
  return {
    t,
    localValue,
    updateGlobalState,
    updateLocalState,
    searchLabel,
    label,
    setLabel,
    defaultChoices,
    handleClear,
    onSearch,
  };
};

export default useData;

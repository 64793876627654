import { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import { UseDataProps } from './types';
import { RadioListQcFieldName } from './const';
import { AllFieldValueTypes, Choice, ComboDesc, FieldDesc, SharedList } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';
import { useQCUpdateFunctionsContext } from '20.formLib/DeclarationContainer/contexts';
import { parseChoice } from '70.quickConnect.Common/fields';
import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';

const useData = (
  field: FieldDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
): UseDataProps => {
  // On récupère le store
  const {
    SharedListStore: { getSharedList },
  } = useStore();

  const { updateProperty } = useQCUpdateFunctionsContext();
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const comboDesc = field as ComboDesc;
  const { listId, listChoice, toolTip, id, fullPathId, defaultValue, value, filterDef } = comboDesc;
  const nameWithId = `${RadioListQcFieldName}-${id}`;
  const [choices, setChoices] = useState<Choice[] | undefined>(listChoice);

  // On définit les callbacks
  const initializeDefaultValue = useCallback(
    (sharedList: SharedList) => {
      const { data } = sharedList;
      if (defaultValue && value === undefined) {
        const defaultChoice = parseChoice(defaultValue);

        if (!defaultChoice) return;

        const choice = data.find(
          ({ label, value: choiceValue }: Choice) =>
            StringExtension.isTheSame(defaultChoice.label, label) ||
            StringExtension.isTheSame(defaultChoice.value, choiceValue),
        );

        if (choice) field.value = choice;
      }
    },
    [defaultValue, value, field],
  );

  // Récupération des choices
  const lookedForList = useRef(false);
  useEffect(() => {
    if (listId && lookedForList.current === false) {
      getSharedList(listId).then((sharedList) => {
        if (sharedList) {
          setChoices(toJS(listChoice ?? sharedList.data));
          updateProperty(fullPathId, 'listChoice', listChoice ?? sharedList.data);
          // Initialisation des valeurs par défaut:
          initializeDefaultValue(sharedList);
        } else setChoices([{ label: 'not found', value: 'string' } as Choice]);
      });

      lookedForList.current = true;
    }
  }, [choices, getSharedList, listId, initializeDefaultValue, defaultValue, fullPathId, updateProperty, listChoice]);

  useEffect(() => {
    if (listChoice && filterDef) {
      setChoices(listChoice);
    }
  }, [filterDef, listChoice]);

  useEffect(() => {
    if (!listChoice || !field.value || !filterDef) return;

    const currentValue = field.value as Choice;
    const exists = listChoice.some((choice) => choice.value === currentValue.value);

    if (!exists) {
      updateDeclaration(field.fullPathId, '');
    }
  }, [listChoice, field.value, field.fullPathId, updateDeclaration, filterDef]);

  // On retourne les valeurs à la vue
  return {
    t,
    choices,
    nameWithId,
    toolTip,
    id,
    fullPathId,
  };
};

export default useData;

import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTheme, Box, FormLabel, IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { HierarchicalListQcFieldName } from './const';
import useData from './hooks';
import HierarchicalListRightContent from './HierarchicalListRightContent';
import HierarchicalListSummary from './HierarchicalListSummary';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { BoxForLabel, FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';
import { HierarchicalChoice, HierarchicalDesc } from '90.quickConnect.Models/models';
import TwoSidesRenderer from '50.quickConnect.Fields/TwoSidesRenderer';
import ChevronRight from '10.quickConnect.app/components/shared/ChevronRight';
import { dippedGreen500 } from '00.app/theme';

const HierarchicalListQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  selectedIndex,
  setSelectedIndex,
  isReadOnly,
  labelClass,
  openByImagesGroupField,
}: ValueFieldsTypesProps) => {
  const hierarchicalDesc = field as HierarchicalDesc;
  const { toolTip, id, value, fullPathId } = hierarchicalDesc;
  const nameWithId = `${HierarchicalListQcFieldName}-${id}`;
  const { hierarchicalChoices, t, deletedItem, setDeleteItem, handleDeleteAll, isDeleteAll, setIsDeleteAll } = useData(
    hierarchicalDesc,
    updateDeclaration,
  );

  const { breakpoints, palette } = useTheme();

  // Étape 1 : extraire tous les values valides depuis hierarchicalChoices
  const validHierarchicalValues = useMemo(() => {
    const extractValues = (choices?: HierarchicalChoice[]): string[] => {
      if (!choices) return [];
      const result: string[] = [];

      const traverse = (nodes: HierarchicalChoice[]) => {
        for (const node of nodes) {
          if (node.value) result.push(node.value);
          if (Array.isArray(node.children) && node.children.length > 0) {
            traverse(node.children);
          }
        }
      };

      traverse(choices);
      return result;
    };

    return extractValues(hierarchicalChoices);
  }, [hierarchicalChoices]);

  // Étape 2 : filtrer les valeurs sélectionnées
  const filteredValue = useMemo(() => {
    if (!Array.isArray(value)) return [];

    return (value as HierarchicalChoice[]).filter((v) => validHierarchicalValues.includes(v.value));
  }, [value, validHierarchicalValues]);

  // Étape 3 : faire updateDeclaration si besoin
  useEffect(() => {
    if (Array.isArray(value) && filteredValue.length !== value.length && filteredValue[0] !== undefined) {
      updateDeclaration(fullPathId, filteredValue);
      setSelectedIndex?.(undefined); // facultatif si tu veux fermer
    }
  }, [filteredValue, value, updateDeclaration, fullPathId, setSelectedIndex]);

  const labelBoxWithChevron = (
    <Box sx={FlexRow}>
      <FormLabel component="legend" id={`${nameWithId}-label`} htmlFor={`${nameWithId}-select`} sx={{ flexGrow: 1 }}>
        {labelSpan}
      </FormLabel>
      {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
      {value && (
        <IconButton
          data-cy={`${nameWithId}-clearAll`}
          sx={{ zIndex: 1 }}
          disabled={isReadOnly}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDeleteAll(e)}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      )}
      <ChevronRight title={t('open_list').toString()} nameWithId color="secondary" />
    </Box>
  );
  const labelBox = (
    <Box sx={FlexRow}>
      <FormLabel component="legend" id={`${nameWithId}-label`} htmlFor={`${nameWithId}-select`} sx={{ flexGrow: 1 }}>
        {labelSpan}
      </FormLabel>
      {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
    </Box>
  );

  if (openByImagesGroupField)
    return (
      <>
        {hierarchicalChoices && (
          <HierarchicalListRightContent
            isDeleteAll={isDeleteAll}
            setIsDeleteAll={setIsDeleteAll}
            deletedItem={deletedItem}
            setDeleteItem={setDeleteItem}
            hierarchicalChoices={hierarchicalChoices}
            updateDeclaration={updateDeclaration}
            labelBox={labelBox}
            hierarchicalDesc={hierarchicalDesc}
          />
        )}
      </>
    );

  return (
    <Box
      className={labelClass}
      id={`scrollToAnchor-${fullPathId}`}
      sx={FieldMainBox(breakpoints)}
      data-cy={`${nameWithId}`}
    >
      <Box
        data-cy={`${nameWithId}-OpenRightPanelBox`}
        sx={{ ...BoxForLabel(palette), borderColor: selectedIndex === id ? dippedGreen500 : '' }}
        onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
      >
        {labelBoxWithChevron}
        {value && (
          <HierarchicalListSummary
            setDeleteItem={setDeleteItem}
            deletedItem={deletedItem}
            hierarchicalDesc={hierarchicalDesc}
            updateDeclaration={updateDeclaration}
            value={filteredValue}
            parentId={fullPathId}
            isReadOnly={isReadOnly}
            setSelectedIndex={setSelectedIndex}
          />
        )}
      </Box>

      {!isReadOnly && selectedIndex === fullPathId && (
        <TwoSidesRenderer
          parentNameAndId={nameWithId}
          onClose={() => setSelectedIndex(undefined)}
          open={selectedIndex === fullPathId}
        >
          {hierarchicalChoices && (
            <HierarchicalListRightContent
              isDeleteAll={isDeleteAll}
              setIsDeleteAll={setIsDeleteAll}
              deletedItem={deletedItem}
              setDeleteItem={setDeleteItem}
              hierarchicalChoices={hierarchicalChoices}
              updateDeclaration={updateDeclaration}
              labelBox={labelBox}
              hierarchicalDesc={hierarchicalDesc}
            />
          )}
        </TwoSidesRenderer>
      )}
    </Box>
  );
};
export default observer(HierarchicalListQcField);

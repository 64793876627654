import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import { CheckBoxListQcFieldName } from './const';
import { Choice, FieldDesc, ComboDesc, AllFieldValueTypes } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';
import { useQCUpdateFunctionsContext } from '20.formLib/DeclarationContainer/contexts';

const useData = (
  field: FieldDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
): UseDataProps => {
  // On récupère le store
  const {
    SharedListStore: { getSharedList },
  } = useStore();

  const { updateProperty } = useQCUpdateFunctionsContext();

  // On récupère la translation
  const { t } = useTranslation('declaration');
  const comboDesc = useMemo(() => field as ComboDesc, [field]);
  const { listId, listChoice, id, toolTip, value, fullPathId, filterDef } = comboDesc;
  const nameWithId = `${CheckBoxListQcFieldName}-${id}`;
  const [isDeleteAll, setIsDeleteAll] = useState<boolean>(false);
  const [choices, setChoices] = useState<Choice[]>(listChoice ?? []);
  const [isItemDeleted, setIsItemDeleted] = useState<string>();

  const deleteItem = useCallback(
    (choice: Choice) => {
      // TODO: Faire un reduce
      const filteredValue = (value as Choice[]).filter(
        (it) => !(it.value === choice.value && it.label === choice.label),
      );
      updateDeclaration(fullPathId, filteredValue);
      setIsItemDeleted(choice.label ?? choice.value);
    },
    [fullPathId, updateDeclaration, value],
  );

  const handleDeleteAll = useCallback(() => {
    updateDeclaration(fullPathId, []);
    setIsDeleteAll(true);
  }, [fullPathId, updateDeclaration]);
  // Récupération des choices
  const lookedForList = useRef(false);

  useEffect(() => {
    if (listId && lookedForList.current === false) {
      getSharedList(listId).then((sharedList) => {
        if (sharedList) {
          setChoices(listChoice ?? sharedList.data);
          updateProperty(fullPathId, 'listChoice', listChoice ?? sharedList.data);
        } else setChoices([{ label: 'not found', value: 'string' } as Choice]);
      });
      lookedForList.current = true;
    }
  }, [listId, getSharedList, choices, updateProperty, fullPathId, listChoice]);

  useEffect(() => {
    if (listChoice && filterDef) {
      setChoices(listChoice);
    }
  }, [filterDef, listChoice]);

  // On retourne les valeurs à la vue
  return {
    t,
    choices,
    id,
    nameWithId,
    toolTip,
    deleteItem,
    isItemDeleted,
    setIsItemDeleted,
    handleDeleteAll,
    isDeleteAll,
    setIsDeleteAll,
  };
};

export default useData;

import { FieldDesc } from '90.quickConnect.Models/models';

const updateVisibilityFieldOrChild = (
  fullPathId: string,
  field: FieldDesc,
  newVisibility: boolean,
): [FieldDesc, boolean] => {
  let changeMade = false;
  if (field.fullPathId.toLowerCase() === fullPathId.toLowerCase() && field.visible !== newVisibility) {
    changeMade = true;
    field.isVisible = newVisibility;
    if (typeof field.visible !== 'object') {
      field.isVisibleOnDevice = newVisibility;
      field.visible = newVisibility;
    }
  }
  if (!changeMade && field.items !== undefined && field.items.length > 0) {
    field.items.forEach((child) => {
      const [updatedChild, childChanged] = updateVisibilityFieldOrChild(fullPathId, child, newVisibility);
      if (childChanged) changeMade = childChanged;
      child = updatedChild;
    });
  }
  return [field, changeMade];
};

export default updateVisibilityFieldOrChild;

import React from 'react';
import useData from './hooks';
import { FilteredListsResolverProps } from './types';
import FilteredListResolver from './FilteredListResolver';

/**
 * Ce composant permet des géré les champs avec des listes
 * @param param0
 * @returns
 */
const FilteredListsResolver = ({
  children,
  flattenFields,
  updateDeclaration,
  forceFieldUpdate,
}: FilteredListsResolverProps) => {
  const { allListFieldsWithFilter } = useData(flattenFields);

  return (
    <>
      {allListFieldsWithFilter.map((fieldListWithFilter) => (
        <FilteredListResolver
          key={fieldListWithFilter.fullPathId}
          fieldListWithFilter={fieldListWithFilter}
          updateDeclaration={updateDeclaration}
          otherFields={flattenFields.filter((f) => f.fullPathId !== fieldListWithFilter.fullPathId)}
          forceFieldUpdate={forceFieldUpdate}
        />
      ))}
      {children}
    </>
  );
};

export default FilteredListsResolver;

import { FieldDesc, IncludeDesc } from '90.quickConnect.Models/models';
import { FieldType, Mandatory } from '90.quickConnect.Models/enums';

export const isMandatoryItems = (fieldDesc: FieldDesc): boolean => {
  if (fieldDesc.items !== undefined && fieldDesc.items.length > 0) {
    return fieldDesc.items.some((i) => isMandatoryItems(i));
  }

  return (
    fieldDesc.mandatory !== undefined &&
    fieldDesc.mandatory === Mandatory.Required &&
    fieldDesc.visible === true &&
    fieldDesc.fieldType !== FieldType.CheckBox
  );
};

export const getMandatoryInTemplate = (
  { items, mandatory }: FieldDesc,
  templateBodies: Record<string, string>,
): Mandatory | undefined => {
  const allItemsInFieldToCheck: FieldDesc[] = items.reduce((acc: FieldDesc[], item: FieldDesc) => {
    const { fieldType } = item;
    if (fieldType !== FieldType.Include) return acc;

    const { modelId } = item as IncludeDesc;

    const tplParsed = JSON.parse(templateBodies[modelId]) as FieldDesc[];

    return [...acc, ...tplParsed];
  }, [] as FieldDesc[]);

  return allItemsInFieldToCheck.some(isMandatoryItems) ? Mandatory.Required : mandatory;
};
